import {parse} from "query-string";
import * as React from "react";
import {Alert, Button, Col, FormControl, FormGroup, Grid} from "react-bootstrap";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import {Fields, InjectedFormProps, reduxForm, SubmissionError} from "redux-form";
import {NavigationBar} from "../common/ui/Navbar";
import {Logger} from "../common/util/Logger";
import {MappEntConfigurationInfo} from "../model";
import * as paths from "../paths";
import {selectors as startupSelectors} from "../startup/selectors";
import {actions} from "./actions";

interface LoginFormData {
  username: string;
  password: string;
}

interface LoginPageProperties {
  dispatch: (action) => any;
  mappEntConfigurationInfo: MappEntConfigurationInfo;
}

const LOGIN_PAGE_MESSAGES = defineMessages({
  usernamePlaceholder: {
    id: "studio.login-page.username-placeholder",
    defaultMessage: "Username",
  },
  passwordPlaceholder: {
    id: "studio.login-page.password-placeholder",
    defaultMessage: "Password",
  },
});

export class LoginPagePresentation extends React.Component<LoginPageProperties & RouteComponentProps<any> & InjectedIntlProps & InjectedFormProps<LoginFormData, LoginPageProperties>, {}> {

  _logger: Logger = Logger.getLogger("Login");

  renderFields = (fields) => {
    const {intl} = this.props;

    return (
        <div>
          <FormGroup controlId={fields[LOGIN_FORM_USERNAME_FIELD_NAME].name}>
            <FormControl
                type="text"
                {...fields[LOGIN_FORM_USERNAME_FIELD_NAME].input}
                placeholder={intl.formatMessage(LOGIN_PAGE_MESSAGES.usernamePlaceholder)}
            />
          </FormGroup>
          <FormGroup controlId={fields[LOGIN_FORM_PASSWORD_FIELD_NAME].name}>
            <FormControl
                type="password"
                {...fields[LOGIN_FORM_PASSWORD_FIELD_NAME].input}
                placeholder={intl.formatMessage(LOGIN_PAGE_MESSAGES.passwordPlaceholder)}
            />
          </FormGroup>
        </div>
    );
  }

  submitFormValues = (values) => {
    const {dispatch, history, location} = this.props;

    const queryObj = parse(location.search);
    const redirectTo = queryObj.redirect ? queryObj.redirect.toString() : "/";

    return dispatch(actions.login({
      username: values[LOGIN_FORM_USERNAME_FIELD_NAME],
      password: values[LOGIN_FORM_PASSWORD_FIELD_NAME],
      redirect: paths.getPageURL() + "#" + redirectTo,
    })).then(() => {
      history.push(redirectTo);
    }).catch((error) => {
      throw new SubmissionError({_error: error});
    });
  }

  renderError = () => {
    const error = this.props.error as any; //redux-form typings state that error is a string. This is a lie.
    const isNetworkError = !error.response && error.message.includes("Network");
    const additionalErrorInfo = isNetworkError ? "Backend could not be reached. Is it offline?" : null;

    this._logger.error(error.message);

    return (
        <Alert bsStyle="danger">
          <FormattedMessage
              id="studio.login-page.login-failed"
              defaultMessage="Login failed!"
          /> {additionalErrorInfo}
        </Alert>);
  }

  render() {
    const {handleSubmit, submitting, submitFailed, location, mappEntConfigurationInfo} = this.props;
    const message = parse(location.search).message;
    const messageDisplay = message ? (
        <Alert bsStyle="info">
          {message}
        </Alert>) : null;
    if (mappEntConfigurationInfo.securityInfo.isMappEnterprise) {
      return <Redirect to={{
        pathname: "/",
      }}/>;
    } else {
      return (
          <div>
            <NavigationBar isAuthenticated={false}/>
            <Grid fluid style={{padding: 0}}>
              <Col xs={12} style={{padding: 0}}>
                <div className="splash"/>
              </Col>
              <Col xs={8} xsOffset={2} md={4} mdOffset={4}>
                <h3>
                  <FormattedMessage
                      id="studio.login-page.header"
                      defaultMessage="LOGIN"
                  />
                </h3>
                <form onSubmit={handleSubmit(this.submitFormValues)}>
                  {messageDisplay}
                  {submitFailed ? this.renderError() : null}
                  <Fields names={[LOGIN_FORM_USERNAME_FIELD_NAME, LOGIN_FORM_PASSWORD_FIELD_NAME,
                                  LOGIN_FORM_REDIRECT_FIELD_NAME]}
                          component={this.renderFields}/>
                  <div style={{textAlign: "center"}}>
                    {submitting
                     ? (<p>
                          <FormattedMessage id="studio.login-page.loading" defaultMessage="Loading Page..."/>
                        </p>)
                     : <Button type="submit" bsStyle="primary">
                       <FormattedMessage id="studio.login-page.button" defaultMessage="LOGIN"/>
                     </Button>}
                  </div>
                </form>
              </Col>
            </Grid>
          </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    mappEntConfigurationInfo: startupSelectors.getMappEntConfigurationInfo(state),
  };
}

export const LOGIN_FORM_USERNAME_FIELD_NAME = "username";
export const LOGIN_FORM_PASSWORD_FIELD_NAME = "password";
export const LOGIN_FORM_REDIRECT_FIELD_NAME = "redirect";
export const LOGIN_FORM_ID = "loginForm";

export const LoginPage = reduxForm({
  form: LOGIN_FORM_ID,
})(connect(mapStateToProps, null)(withRouter(injectIntl(LoginPagePresentation))));
