import * as Stomp from "@stomp/stompjs";
import {messageCallbackType, Versions} from "@stomp/stompjs";
import * as React from "react";
import {Logger} from "../common/util/Logger";
import {WithApi, WithApiProperties} from "../common/util/WithApi";

// LF-1685: Use wss: iso ws: if Studio is accessed over HTTPS
const isHttps = window.location.protocol === "https:";
const wsProtocol = isHttps ? "wss:" : "ws:";
const TOPIC_PREFIX = "/studio"; //keep in sync with Studio Service's NotificationCenter.TOPIC_PREFIX

interface UpdateClientSubscription {
  topic: string;
  handler: messageCallbackType;
  isSubscribed: boolean;
  subscription?: Stomp.StompSubscription;
}

export interface WithUpdateClientProps {
  subscribe: (studioTopic: string, handler: messageCallbackType) => void;
}

export const WithUpdateClient = <WrappedComponentProps extends {}>(WrappedComponent: React.ComponentClass<WrappedComponentProps & WithUpdateClientProps>) => {

  class WithUpdateClientHOC extends React.Component<WrappedComponentProps & WithApiProperties> {

    client: Stomp.Client;
    subscriptions: UpdateClientSubscription[] = [];
    _logger = Logger.getLogger("UpdateClient");

    componentDidMount() {
      this.setupStompClient();
    }

    componentWillUnmount() {
      this.tearDownStompClient();
    }

    handleError = (e) => {
      this._logger.warn("Could not setup websocket connection", e);
      this.client = null;
    }

    setupStompClient = (): Promise<void> => {
      if (this.client) {
        this.tearDownStompClient();
      }

      try {
        let url = this.props.api.getStudioNotificationsUrl();
        return this.props.api.getMappEnterpriseToken().then((token) => {
          if (token) {
            url += "?access_token=" + token;
          }
          // LF-1858: monkey patch the protocolVersions function on StompJS Versions.default instance to return an
          // empty array, so that StompJS does not include the Sec-WebSocket-Protocol header. WebLogic does not
          // support that header by default, so if the header is included in the websocket request then the browser
          // will reject the connection because the response doesn't contain this header. We have to do this instead
          // of just supplying an empty array for the stompVersions config parameter because doing that would also
          // cause the STOMP CONNECT message to send an empty list of accepted versions, which doesn't work either.
          // This is likely to be brittle and may break if we upgrade to a new version of StompJS, but it's all
          // we've got for now.
          Versions.default.protocolVersions = () => [];
          this.client = new Stomp.Client({
            brokerURL: url,
            reconnectDelay: 300,
            onConnect: (frame) => {
              // this callback gets called not only on initial client creation, but also when the client reconnects
              // after timeout. In that case we need to force topic subscription because our internal state already
              // has marked each topic as subscribed
              this.subscribeToTopics(true);
            },
          });
          this.client.activate();
        }).catch(this.handleError);
      } catch (error) {
        this.handleError(error);
      }
    }

    tearDownStompClient = () => {
      if (this.client) {
        try {
          this.subscriptions.forEach((s, index) => {
            if (s.isSubscribed) {
              s.subscription.unsubscribe();
            }
          });
          this.subscriptions = [];
          this.client.deactivate();
        } catch (error) {
          this._logger.warn("Error while disconnecting WebSocket client", error);
        } finally {
          this.client = null;
        }
      }
    }

    handleSubscribe = (topic: string, handler: messageCallbackType) => {
      this.subscriptions.push({topic, isSubscribed: false, handler});
      this.subscribeToTopics(false);
    }

    render() {
      return <WrappedComponent {...this.props} subscribe={this.handleSubscribe}/>;
    }

    private subscribeToTopics = (force: boolean) => {
      if (!this.client) {
        return;
      }
      this.subscriptions.map((s, index) => {
        if (!s.isSubscribed || force) {
          const stompSubscription = this.client.subscribe(TOPIC_PREFIX + s.topic, s.handler);
          this.subscriptions[index] = {...s, isSubscribed: true, subscription: stompSubscription};
        }
      });
    }
  }

  return WithApi(WithUpdateClientHOC);
};
