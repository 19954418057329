import {setLicenseText} from "@luciad/ria/util/License";

// tslint:disable-next-line
setLicenseText(require("./license/luciadria-license.txt"));

import {LocaleSetup, setupMomentIntl, setupReactIntl} from "./common/intl/intl";

// has to be done before other modules are imported
setupMomentIntl();

import axios from "axios";
import {SERVER_CONFIG_PATH} from "./paths";
import {createHashHistory} from "history";
import {stringify} from "query-string";
import * as React from "react";
import * as ReactDOM from "react-dom";
import {defineMessages, IntlProvider} from "react-intl";
import {Provider} from "react-redux";
import {HashRouter} from "react-router-dom";
import {applyMiddleware, createStore, Store} from "redux";
import thunk from "redux-thunk";
import {EncodingTextDecoder, EncodingTextEncoder} from "text-encoding";
import {createStudioApi} from "./api/StudioInternalApi";
import {createUserTourApi} from "./api/UserTourInternalApi";
import "./app.less";
import {selectors as toursSelectors} from "./common/ui/tour/selectors";
import {rootReducer} from "./rootReducer";
import {renderRoutes} from "./routes";
import Startup from "./startup/startup";
import {selectors as userSelectors} from "./user/selectors";
import {ServerConfiguration} from "./model";
import {Api} from "./api/InternalApi";
import {ErrorDisplay} from "./common/ui/errordisplay/ErrorDisplay";

// Set up text encoding polyfill if necessary (only needed for MS Edge)
if (!window["TextEncoder"]) {
  window["TextEncoder"] = EncodingTextEncoder;
  window["TextDecoder"] = EncodingTextDecoder;
}

const rootEl = document.getElementById("root");

const reduxdevtools: any = window["devToolsExtension"] ? window["devToolsExtension"]() : (f: any) => f;

const error401Handler = (error) => {
  if (error && error.response && error.response.status === 401) {
    //multiple 401's can come in quick succession, make sure the redirect only happens once!
    const alreadyRedirected = history.location.pathname.indexOf("login") >= 0;
    if (!alreadyRedirected) {
      history.push({
        pathname: "/login",
        search: stringify({
          redirect: history.location.pathname,
          messageId: LOGIN_PAGE_MESSAGES.pleaseAuthenticate.id,
          defaultMessage: LOGIN_PAGE_MESSAGES.pleaseAuthenticate.defaultMessage,
        }),
      });
    }
  }
};

const getApi = (state): Api => {
  return createApi(!userSelectors.getUserTourCompleted(state) || !toursSelectors.getStyleDataTourCompleted(state));
}

export const createApi = (userTourApi: boolean) => {
  if (!_serverConfiguration) {
    throw new Error("ServerConfiguration not loaded");
  }

  if (userTourApi) {
    return createUserTourApi(_serverConfiguration);
  }

  const studioApi = createStudioApi(_serverConfiguration);
  studioApi.addApiErrorHandler(error401Handler);
  return studioApi;
}

const middlewares = [thunk.withExtraArgument({getApi})];

// enable logging of redux actions for the development server
if (process.env.NODE_ENV === 'development') {
  const {logger} = require('redux-logger');
  middlewares.push(logger);
}

const middleware = applyMiddleware(...middlewares);
const store: Store<any> = middleware(reduxdevtools(createStore))(rootReducer);

//ControlRoom uses a HashRouter (urls in the form of /studio/#/services/1).
//The advantage of this over BrowserHistory ('cleaner' urls like /studio/services/1) is that the server hosting
//ControlRoom needs no special configuration. With a BrowserHistory, the server needs to
//serve index.html for every client-side path (e.g. studio/services/1 and studio/products/2).
//Using BrowserHistory, ControlRoom also needs be aware of the 'base' path it is deployed on. This is not the case
//for HashRouter. Because of this, it's harder to deploy ControlRoom on different server paths and/or behind
//reverse proxies when using BrowserHistory.
//The only downsides of HashRouter compared to BrowserHistory are that URL's are less pretty (there's a hash/anchor)
//inside them) and server-side rendering is not supported (we don't do this anyway).
const history = createHashHistory();

const LOGIN_PAGE_MESSAGES = defineMessages({
  pleaseAuthenticate: {
    id: "studio.login.please-authenticate",
    defaultMessage: "Please Authenticate",
  },
});

const renderApp = (result: LocaleSetup) => {
  ReactDOM.render((
      <IntlProvider locale={result.locale} messages={result.messages}>
        <Provider store={store}>
          <Startup history={history}>
            <HashRouter>
              {renderRoutes()}
            </HashRouter>
          </Startup>
        </Provider>
      </IntlProvider>
  ), rootEl);
};

const renderError = (error: Error) => {
  ReactDOM.render((
      <IntlProvider>
        <ErrorDisplay error={error}/>
      </IntlProvider>
  ), rootEl);
}

let _serverConfiguration: ServerConfiguration;

const loadServerConfiguration = (): Promise<ServerConfiguration> => {
  return axios.get(SERVER_CONFIG_PATH)
      .then(response => response.data as ServerConfiguration)
      .catch(error => {
        const connectionError: Error = new Error("Failed to acquire LuciadFusion server configuration: " + error.message);
        connectionError.stack = error.stack;
        throw connectionError
      });
}

Promise.all([loadServerConfiguration(), setupReactIntl()])
    .then(([serverConfig, localeSetup]) => {
      _serverConfiguration = serverConfig as ServerConfiguration;
      renderApp(localeSetup as LocaleSetup);
    }).catch(error => renderError(error));