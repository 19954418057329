import {MappEntConfigurationInfo} from "../model";
import {StartupState} from "./reducer";

export const moduleName = "startup";

const getStartupState = (state) => (state[moduleName] || {}) as StartupState;

const getMappEntConfigurationInfo = (state): MappEntConfigurationInfo => {
  return getStartupState(state).mappEntConfigurationInfo;
};

const isMappEnterprise = (state): boolean => {
  const config = getMappEntConfigurationInfo(state);
  return config && config.securityInfo && config.securityInfo.isMappEnterprise;
};

export const selectors = {
  getMappEntConfigurationInfo,
  isMappEnterprise,
};
