import axios, {AxiosRequestConfig, CancelToken} from "axios";
import * as moment from "moment";
import {stringify} from "query-string";
import {BackgroundDataInfo, PreviewMetadata} from "../common/ui/riamap/model";
import {ValidationResult} from "../common/ui/validation/ProductValidation";
import {ServiceTypeValidation} from "../common/ui/validation/ServiceTypeValidation";
import {throttle} from "../common/util/PerformanceUtil";
import {FileInfo, ImportedData, ImportedDataSearchFilter} from "../data/model";
import {FileInfoSearchFilter, ImportJob, PreprocessJob} from "../jobs/model";
import {BasePath, Bounds, DirectoryItem, EntityType, FileUploadStatus, ServerConfiguration} from "../model";
import {DefaultMetadata} from "../common/defaultMetadata/model";
import * as paths from "../paths";
import {Product, ProductFilter, StyledData} from "../products/model";
import {ServiceFilter} from "../services/filter/model";
import {ContactInfo, Service, ServiceStatus, ServiceTypeDetails} from "../services/model";
import {DataRoot} from "../settings/dataroots/model";
import {StyleSearchFilter} from "../styles/filter/model";
import {Style} from "../styles/model";
import {User} from "../user/model";
import {Api, ErrorHandlerHandle, LoginOptions} from "./InternalApi";
import {DeleteResponse, ProductInfo} from "../common/model";

/**
 * InternalAPI implementation that interfaces with the Studio Backend (service).
 */

interface METoken {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  expiration_date: number;
}

const MIN_TIME_BETWEEN_UPLOAD_PROGRESS_EVENTS = 100; //in milliseconds

axios.defaults.withCredentials = true;

// local helpers
const toData = (response) => response.data;

const throwIfFalse = (errorMessage) => (response) => {
  if (response.data === false) {
    throw new Error(errorMessage);
  }
};
const throwOnStatusNot404 = (error) => {
  if (error.status !== 404) { //404 might be returned when deleting something that did not exist, ignore those cases
    throw error;
  }
};

const convertToBackendDateString = (dateString: string) => {
  return moment(dateString).format();
};

/**
 * Creates a transformer that converts date strings to the backend date format.
 * @param object the object to transform
 * @param datePropertiesToTransform the names of the date properties to transform
 * @returns {(request:any)=>any} A transformer function that transforms dates to the backend format
 */
const transformDates = (object: any, datePropertiesToTransform: string[]) => {
  const copy = Object.assign({}, object);
  if (!object) {
    return copy;
  }
  datePropertiesToTransform.forEach((dateProperty) => {
    if (copy[dateProperty]) {
      copy[dateProperty] = convertToBackendDateString(object[dateProperty]);
    }
  });
  return copy;
};

const formatFilter = (filter = null) => {
  return Object.assign({}, filter, {
    startDate: (filter && filter.startDate) ? moment(filter.startDate).format() : undefined,
    endDate: (filter && filter.endDate) ? moment(filter.endDate).format() : undefined,
  });
};

const formatApiFilter = (filter = null, applySort = true) => {
  const apiFilter = formatFilter(filter);
  apiFilter.date = apiFilter.startDate;
  if (apiFilter.endDate) {
    if (apiFilter.date) {
      apiFilter.date += "/";
    } else {
      apiFilter.date = "/";
    }
    apiFilter.date += apiFilter.endDate;
  }
  delete apiFilter.startDate;
  delete apiFilter.endDate;
  if (filter.maxResults) {
    apiFilter.pageSize = filter.maxResults;
    delete apiFilter.maxResults;
    apiFilter.page = (filter.offset / apiFilter.pageSize) + 1;
    delete apiFilter.offset;
  }
  if (applySort) {
    // default to these sort options to match the previous functionality
    apiFilter.sortBy = "creationTime";
    apiFilter.sortOrder = "DESC";
  }
  return apiFilter;
};

const toFormData = (fields = {}) => {
  const formData = new FormData();
  for (const name in fields) {
    if (fields.hasOwnProperty(name)) {
      formData.append(name, fields[name]);
    }
  }
  return formData;
};

const entityPath = (entityType: EntityType) => {
  switch (entityType) {
  case EntityType.TYPE_DATA :
    return paths.IMPORTEDDATA_PATH;
  case EntityType.TYPE_STYLE :
    return paths.STYLE_PATH;
  case EntityType.TYPE_PRODUCT :
    return paths.PRODUCT_PATH;
  case EntityType.TYPE_SERVICE :
    return paths.SERVICE_PATH;
  }
  throw new Error("Unexpected entity type");
};

// These map* functions map the response of the private API to the new model which is based on the public API

const mapProduct = (p) => ({
  id: p.publicId,
  wgs84Bounds: p.bounds,
  sequenceNumber: p.sequenceNumber,
  name: p.name,
  title: p.metadata.title,
  abstractText: p.metadata.abstract,
  keywords: p.metadata.keywords,
  contents: p.contents,
  creationTime: p.creationTime,
  createdBy: p.createdBy,
  validationByServiceType: p.validationByServiceType,
  type: p.type,
} as Product);

const mapData = (d) => ({
  id: d.publicId,
  creationTime: d.creationTime,
  title: d.title,
  wgs84Bounds: d.wgs84Bounds,
  abstractText: d.abstract,
  keywords: d.keywords,
  type: d.type,
  filePath: d.entryPointFile && d.entryPointFile.filePath,
} as ImportedData);

const mapService = (s) => ({
  id: s.metadata.publicId,
  name: s.name,
  title: s.metadata.title,
  abstractText: s.metadata.abstract,
  keywords: s.metadata.keywords,
  type: s.metadata.type,
  products: s.contents,
  creationTime: s.creationTime,
  updateTime: s.updateTime,
  createdBy: s.createdBy,
  status: mapServiceStatus(s.status),
  startedTime: s.startedTime,
  endpointPath: s.endpointPath,
  accessConstraint: s.accessConstraint,
  canDelete: !s.deleteProtected,
  contactInformation: mapContactInformation(s.contactInformation),
} as Service);

const mapServiceStatus = (t) => {
  if (t === "RUNNING") {
    return ServiceStatus.RUNNING;
  } else if (t === "STOPPED") {
    return ServiceStatus.STOPPED;
  } else if (t === "PENDING") {
    return ServiceStatus.PENDING;
  }
  return t;
}

const mapContactInformation = (c) => {
  if (!c) {
    return null;
  }

  return {...c} as ContactInfo;
}

const mapStyle = (s) => ({
  id: s.publicId,
  name: s.name,
  abstractText: s.metadata.abstract,
  title: s.metadata.title,
  type: s.metadata.type,
  keywords: s.metadata.keywords,
  filePath: s.fileInfo.filePath,
  createdBy: {
    username: s.createdBy.username,
  },
  creationTime: s.creationTime,
  updateTime: s.updateTime,
  content: s.content,
} as Style);

const mapStyledData = (sd) => ({
  id: sd.dataPublicId,
  data: sd.dataPublicId,
  dataTitle: sd.data.title,
  style: sd.styleSetItems[0].stylePublicId,
  styleTitle: sd.styleSetItems[0].styleTitle || (sd.style && sd.style.metadata && sd.style.metadata.title),
  productId: sd.productPublicId,
  visible: sd.visible,
} as StyledData);

export class ControlRoomApiClass implements Api {

  _errorHandlers: ((error: Error) => void)[] = [];
  private _studioServiceAPIPath: string;
  private _studioApiPath: string;
  private _actuatorsPath: string;
  private _serverConfig: ServerConfiguration;
  // Typically an OAuth2 app would store its auth token in local storage so that it persists across sessions. But a user
  // would also typically log into the app itself, which we aren't doing here. Since our desired MappEnt integrated
  // workflow is to launch ControlRoom from ME Studio, I don't think we have to bother supporting token storage that
  // will persist across browser sessions.
  private mappEntToken: METoken;
  private mappEntUrl: string;
  private mappEntTenant: string;
  private mappEntRefreshPromise: Promise<METoken>;

  constructor(serverConfiguration: ServerConfiguration) {
    this._serverConfig = serverConfiguration;
    this._studioServiceAPIPath = this._getAbsoluteUrl(serverConfiguration.studio.internalApi);
    this._studioApiPath = this._getAbsoluteUrl(serverConfiguration.studio.restApi);
    this._actuatorsPath = this._getAbsoluteUrl(serverConfiguration.actuators);
    this.mappEntToken = null;
  }

  /**
   * returns the origin of the current page with the context path appended to it
   */
  private _getAbsoluteBaseUrl(): string {
    const origin = paths.getOrigin();
    const contextPath = this._getContextPath();
    return `${origin}${contextPath}`;
  };

  /**
   * Returns the context path of the application.
   */
  private _getContextPath = () => {
    // e.g. /app/foo/bar/studio/index.html#/services/1
    const path = window.location.pathname;
    // strip everything after /studio
    return path.slice(0, path.lastIndexOf(this._serverConfig.studio.webApp.basePath));
  };

  private _getAbsoluteUrl(basePath: BasePath): string {
    return this.getAbsoluteUrl(basePath.basePath);
  }

  getAbsoluteUrl(basePath: string): string {
    return this._getAbsoluteBaseUrl() + basePath;
  }

  getStudioInternalApiUrl(): string {
    return this._getAbsoluteUrl(this._serverConfig.studio.internalApi);
  }

  getStudioNotificationsUrl(): string {
    return this._getAbsoluteUrl(this._serverConfig.studio.notifications);
  }

  getDataPreviewBaseUrl(): string {
    return this._getAbsoluteUrl(this._serverConfig.wms) + "/" + this._serverConfig.studio.dataPreviewPrefix;
  }

  getStylePreviewBaseUrl(): string {
    return this._getAbsoluteUrl(this._serverConfig.wms) + "/" + this._serverConfig.studio.stylePreviewPrefix;
  }

  getProductPreviewBaseUrl(): string {
    return this._getAbsoluteUrl(this._serverConfig.wms) + "/" + this._serverConfig.studio.productPreviewPrefix;
  }

  getPreviewBackgroundUrl(): string {
    return this._getAbsoluteUrl(this._serverConfig.wms) + "/" + this.getBackgroundDataName();
  }

  getBackgroundDataName(): string {
    return this._serverConfig.studio.previewBackgroundDataName
  }

  getDataRoots = () => {
    return axios
        .get(this._studioApiPath + "/data-roots")
        .then((r) => r.data.dataRoots)
        .catch(this.notifyErrorHandlers) as Promise<DataRoot[]>;
  }

  registerContentRoot = (contentRoot: DataRoot) => {
    return axios
        .post(this._studioApiPath + "/data-roots", contentRoot)
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<DataRoot>;
  }

  deleteContentRoot = (dataRootId: string) => {
    return axios
        .delete(this._studioApiPath + "/data-roots/" + dataRootId)
        .then(toData) as Promise<boolean>;
  }

  getMaxUploadSize = () => {
    return axios
        .get(this._studioServiceAPIPath + "/upload/size")
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<number>;
  }

  uploadFile = (data: FormData, fileUploadProgress, cancelToken: CancelToken,
                uploadUri: string): Promise<FileUploadStatus[]> => {
    const config: AxiosRequestConfig = {
      headers: {"Content-Type": "multipart/form-data"},
      responseType: "text",
      cancelToken,
    };

    if (fileUploadProgress) {
      //V170-587: Safari fires so many onprogress events that it chokes. Throttle the amount of onprogress events we handle.
      config.onUploadProgress = throttle((progressEvent) => {
        const percentComplete = (progressEvent.loaded / progressEvent.total) * 100;
        fileUploadProgress(percentComplete);
      }, MIN_TIME_BETWEEN_UPLOAD_PROGRESS_EVENTS);
    }

    return axios
        .post(this._studioServiceAPIPath + uploadUri, data, config)
        .then((r) => r.data instanceof Array ? r.data : [r.data])
        .catch(this.notifyErrorHandlers) as Promise<FileUploadStatus[]>;
  }

  downloadMetadata = (data: ImportedData) => {
    window.open(this._studioServiceAPIPath + paths.IMPORTEDDATA_PATH + "/" + data.id + "/metadata/download");
  }

  downloadServiceMetadata = (service: Service) => {
    window.open(this._studioServiceAPIPath + paths.SERVICE_PATH + "/" + service.id + "/metadata/download");
  }

  browse = (rootPath: string) => {
    return axios
        .post(this._studioServiceAPIPath + "/browse", rootPath, {headers: {"Content-Type": "text/plain"}})
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<DirectoryItem[]>;
  }

  getImportedData = (filter: ImportedDataSearchFilter) => {
    // The public API does not yet support an anyText search filter, so if that is specified then continue to use the
    // private API
    if (filter && filter.anyText) {
      return axios
          .get(this._studioServiceAPIPath + paths.IMPORTEDDATA_PATH,
              {params: transformDates(filter, ["startDate", "endDate"])})
          .then((r) => r.data.map(mapData))
          .catch(this.notifyErrorHandlers) as Promise<ImportedData[]>;
    } else {
      const apiFilter = formatApiFilter(filter);
      return axios
          .get(this._studioApiPath + "/data", {params: apiFilter})
          .then((r) => r.data.data)
          .catch(this.notifyErrorHandlers) as Promise<ImportedData[]>;
    }
  }

  getImportedDataById = (id: string) => {
    return axios
        .get(this._studioApiPath + "/data/" + id)
        .then((r) => r.data.data)
        .catch(this.notifyErrorHandlers) as Promise<ImportedData>;
  }

  getAllImportedDataFilesByImportedDataId = (id: string) => {
    return axios
        .get(this._studioServiceAPIPath + paths.IMPORTEDDATA_PATH + "/" + id + "/files")
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<FileInfo[]>;
  }

  deleteImportedData = (ids: string[]) => {
    return axios
        .delete(this._studioApiPath + "/data", {data: ids})
        .then((r) => {
          if (r.data) {
            return r.data.warnings;
          }
        })
        .catch(this.notifyErrorHandlers) as Promise<DeleteResponse>;
  }

  addImportedData = (filePath: string) => {
    return axios
        .post(this._studioApiPath + "/data", {filePath})
        .then((r) => r.data.data)
        .catch(this.notifyErrorHandlers) as Promise<ImportedData[]>;
  }

  refreshImportedData = (id: string) => {
    return axios
        .put(this._studioApiPath + "/data/" + id + "/refresh")
        .then((r) => r.data.data)
        .catch(this.notifyErrorHandlers) as Promise<ImportedData>;
  }

  updateImportedData = (data: ImportedData) => {
    return axios
        .patch(this._studioApiPath + "/data/" + data.id, {
          title: data.title,
          abstractText: data.abstractText,
          keywords: data.keywords,
        })
        .then((r) => r.data.data)
        .catch(this.notifyErrorHandlers) as Promise<ImportedData>;
  }

  listServices = (filter: ServiceFilter = null) => {
    // TODO: Add support for these filters to the public API
    if (filter && filter.anyText) {
      return axios
          .get(this._studioServiceAPIPath + paths.SERVICE_PATH, {params: formatFilter(filter)})
          .then((r) => r.data.map(mapService))
          .catch(this.notifyErrorHandlers) as Promise<Service[]>;
    } else {
      return axios
          .get(this._studioApiPath + paths.SERVICE_PATH, {params: formatApiFilter(filter)})
          .then((r) => r.data.services)
          .catch(this.notifyErrorHandlers) as Promise<Service[]>;
    }
  }

  serviceById = (id: string) => {
    return axios
        .get(this._studioApiPath + paths.SERVICE_PATH + "/" + id)
        .then((r) => r.data.service)
        .catch(this.notifyErrorHandlers) as Promise<Service>;
  }

  serviceByName = (name: string) => {
    return axios
        .get(this._studioApiPath + paths.SERVICE_PATH, {params: {name}})
        .then((r) => {
          const services = r.data.services;
          if (services && services.length) {
            return services[0];
          } else {
            return null;
          }
        })
        .catch(this.notifyErrorHandlers) as Promise<Service>;
  }

  isServiceStarted = (id: string) => {
    return this.serviceById(id).then((s) => s.status === ServiceStatus.RUNNING);
  }

  createService = (service: Service) => {
    return axios
        .post(this._studioApiPath + paths.SERVICE_PATH, {
          title: service.title,
          name: service.name,
          type: service.type,
          abstractText: service.abstractText,
          keywords: service.keywords,
          meshCompression: service.meshCompression,
          pointCloudCompression: service.pointCloudCompression,
          wfsTransactionsEnabled: service.wfsTransactionsEnabled,
          preprocessingOutputPath: service.preprocessingOutputPath,
          accessConstraint: service.accessConstraint,
          contactInformation: mapContactInformation(service.contactInformation),
        })
        .then((r) => r.data.service)
        .catch(this.notifyErrorHandlers) as Promise<Service>;
  }

  updateService = (service: Service) => {
    return axios
        // remove products from the payload, it is not defined on the server object model and unknown properties will
        // raise an exception
        .patch(this._studioApiPath + paths.SERVICE_PATH + "/" + service.id, {...service, products: undefined})
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<Service>;
  }

  deleteService = (id: string) => {
    return axios
        .delete(this._studioApiPath + paths.SERVICE_PATH + "/" + id)
        .then(throwIfFalse("Could not delete service with id: " + id))
        .catch(throwOnStatusNot404) as Promise<void>;
  }

  startService = (id: string) => {
    return axios
        .put(this._studioApiPath + paths.SERVICE_PATH + "/" + id + "/start")
        .then((r) => r.data.service)
        .catch(this.notifyErrorHandlers) as Promise<Service>;
  }

  stopService = (id: string) => {
    return axios
        .put(this._studioApiPath + paths.SERVICE_PATH + "/" + id + "/stop")
        .then((r) => r.data.service)
        .catch(this.notifyErrorHandlers) as Promise<Service>;
  }

  getFullServiceById = (id: string) => {
    let resolvedService = null;
    return this.serviceById(id).then((service) => {
      resolvedService = service;
      return this.getProductsForService(id);
    }).then((serviceProducts) => {
      resolvedService.products = serviceProducts;
      return resolvedService;
    }) as Promise<Service>;
  }

  getProductsForService = (serviceId: string) => {
    return axios
        .get(this._studioServiceAPIPath + paths.SERVICE_PATH + "/" + serviceId + "/products")
        .then((r) => r.data.map(mapProduct))
        .catch(this.notifyErrorHandlers) as Promise<Product[]>;
  }

  addProductToService = (serviceId: string, productId: string) => {
    return axios
        .post(this._studioServiceAPIPath + paths.SERVICE_PATH + "/" + serviceId + "/add-product/" + productId)
        .then(throwIfFalse(`Product with id:${productId} could not be added to service ${serviceId}`));
  }

  addProductsToService = (serviceId: string, productIds: string[]) => {
    return axios
        .post(this._studioServiceAPIPath + paths.SERVICE_PATH + "/" + serviceId + "/add-products", productIds)
        .then(throwIfFalse(`Product with id:${productIds} could not be added to service ${serviceId}`));
  }

  validateProduct = (serviceId: string, productId: string) => {
    return axios
        .get(this._studioServiceAPIPath + paths.SERVICE_PATH + "/" + serviceId + "/validate-product/" + productId)
        .then(toData) as Promise<ValidationResult>;
  }

  validateProducts = (serviceId: string, productIds: string[]) => {
    return axios
        .post(this._studioServiceAPIPath + paths.SERVICE_PATH + "/" + serviceId + "/validate-products", productIds)
        .then(toData) as Promise<ValidationResult[]>;
  }

  serviceTypeValidation = (products: Product[]) => {
    return axios
        .post(this._studioApiPath + paths.SERVICE_PATH + "/types/validate-products",
            products.map(product => ({id: product.id})))
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<ServiceTypeValidation>;
  }

  setServiceProducts = (serviceId: string, products: Product[]) => {
    return axios
        .put(this._studioApiPath + paths.SERVICE_PATH + "/" + serviceId + "/products",
            products.map((p) => ({id: p.id})))
        .then(throwIfFalse(`Products ${products.map((p) => p.id)} could not be added to service ${serviceId}`));
  }

  removeProductFromService = (serviceId: string, productId: string) => {
    return axios
        .post(this._studioServiceAPIPath + paths.SERVICE_PATH + "/" + serviceId + "/remove-product/" + productId)
        .then(throwIfFalse(`Product with id:${productId} could not be removed from service ${serviceId}`));
  }

  createProduct = (product: Product) => {
    return axios
        .post(this._studioApiPath + paths.PRODUCTS_PATH, {
          name: product.name,
          title: product.title,
          abstractText: product.abstractText,
          keywords: product.keywords,
        })
        .then((postResponse) => {
          const p = postResponse.data.product;
          return axios
              .put(this._studioApiPath + paths.PRODUCTS_PATH + "/" + p.id + paths.STYLEDDATASTUDIO_PATH,
                  product.contents.map((sd) => ({
                    data: sd.data,
                    style: sd.style,
                    visible: sd.visible,
                  })))
              .then((putResponse) => {
                p.contents = putResponse.data.styledData;
                return p;
              });
        })
        .catch(this.notifyErrorHandlers) as Promise<Product>;
  }

  loadProducts = (filter: ProductFilter = null) => {
    // TODO: add support for these filters to the public API
    if (filter && (filter.anyText || filter.serviceType)) {
      return axios
          .get(this._studioServiceAPIPath + paths.PRODUCT_PATH, {params: formatFilter(filter)})
          .then((r) => r.data.map(mapProduct))
          .catch(this.notifyErrorHandlers) as Promise<Product[]>;
    } else {
      return axios
          .get(this._studioApiPath + paths.PRODUCTS_PATH, {params: formatApiFilter(filter)})
          .then((r) => r.data.products)
          .catch(this.notifyErrorHandlers) as Promise<Product[]>;
    }
  }

  updateProduct = (product: Product) => {
    return axios
        .patch(this._studioApiPath + paths.PRODUCTS_PATH + "/" + product.id, {
          name: product.name,
          title: product.title,
          abstractText: product.abstractText,
          keywords: product.keywords,
        })
        .then((r) => r.data.product)
        .catch(this.notifyErrorHandlers) as Promise<Product>;
  }

  deleteProduct = (id: string) => {
    return axios
        .delete(this._studioApiPath + paths.PRODUCTS_PATH + "/" + id)
        .then(throwIfFalse("Could not delete product with id: " + id))
        .catch(throwOnStatusNot404) as Promise<void>;
  }

  getStyles = (filter: StyleSearchFilter) => {
    // TODO: add these filters to the public API
    if (filter && filter.anyText) {
      return axios
          .get(this._studioServiceAPIPath + paths.STYLE_PATH, {params: formatFilter(filter)})
          .then((r) => r.data.map(mapStyle))
          .catch(this.notifyErrorHandlers) as Promise<Style[]>;
    } else {
      return axios
          .get(this._studioApiPath + paths.STYLES_PATH, {params: formatApiFilter(filter)})
          .then((r) => r.data.styles)
          .catch(this.notifyErrorHandlers) as Promise<Style[]>;
    }
  }

  getStyleById = (id: string) => {
    return axios
        .get(this._studioApiPath + paths.STYLES_PATH + "/" + id)
        .then((r) => r.data.style)
        .catch(this.notifyErrorHandlers) as Promise<Style>;
  }

  deleteStyles = (ids: string[]) => {
    return axios
        .delete(this._studioApiPath + "/styles", {data: ids})
        .catch(this.notifyErrorHandlers);
  }

  addStyle = (filePath: string) => {
    return axios
        .post(this._studioApiPath + paths.STYLES_PATH, {filePath})
        .then((r) => r.data.style)
        .catch(this.notifyErrorHandlers) as Promise<Style>;
  }

  refreshStyle = (id: string) => {
    return axios
        .put(this._studioApiPath + paths.STYLES_PATH + "/" + id + "/refresh")
        .then((r) => r.data.style)
        .catch(this.notifyErrorHandlers) as Promise<Style>;
  }

  getActuator = (type: string, headers?: { [headerName: string]: string }) => {
    headers = Object.assign({Range: "bytes=0-100000"}, headers || {});
    return axios
        .get(this._actuatorsPath + "/" + type, {headers})
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<any>;
  }

  getStyleBoundsByType = (type: string) => {
    return axios
        .get(this._studioApiPath + paths.STYLES_PATH + "/bounds/" + type)
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<Bounds>;
  }

  canPreviewStyleType = (type: string) => {
    return axios
        .get(this._studioApiPath + paths.STYLES_PATH + "/can-preview/" + type)
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<boolean>;
  }

  updateStyle = (style: Style) => {
    return axios
        .patch(this._studioApiPath + paths.STYLES_PATH + "/" + style.id, style)
        .then((r) => r.data.style)
        .catch(this.notifyErrorHandlers) as Promise<Style>;
  }

  loadProductContents = (id: string) => {
    return axios
        .get(this._studioApiPath + paths.PRODUCTS_PATH + "/" + id + paths.STYLEDDATASTUDIO_PATH)
        .then((r) => r.data.styledData.map((sd) => ({...sd, id: sd.data, productId: id})))
        .catch(this.notifyErrorHandlers) as Promise<StyledData[]>;
  }

  getProductServices = (id: string) => {
    return axios
        .get(this._studioApiPath + paths.PRODUCTS_PATH + "/" + id + "/services")
        .then((r) => r.data.services)
        .catch(this.notifyErrorHandlers) as Promise<Service[]>;
  }

  getProductById = (id: string) => {
    // this uses the private API to get the validationByServiceType property, not sure we want to expose that
    // in the public API
    return axios
        .get(this._studioServiceAPIPath + paths.PRODUCT_PATH + "/" + id)
        .then((r) => mapProduct(r.data))
        .catch(this.notifyErrorHandlers) as Promise<Product>;
  }

  createOrUpdateStyledData = (productId: string, styledDatas: StyledData[]) => {
    const apiStyledData = styledDatas.map((sd) => ({
      productPublicId: productId,
      dataPublicId: sd.data,
      styleSetItems:
          [{
            stylePublicId: sd.style,
          }],
      visible: sd.visible,
    }));
    return axios
        .post(this._studioServiceAPIPath + paths.PRODUCTDATA_PATH + "/batch-create-or-update", apiStyledData)
        .then((r) => r.data.map(mapStyledData))
        .catch(this.notifyErrorHandlers)
        .catch((error) => {
          throw error;
        }) as Promise<StyledData[]>;
  }

  setStyledData = (productId: string, styledData: StyledData[]) => {
    const apiStyledData = styledData.map((sd) => ({
      data: sd.data,
      style: sd.style,
      visible: sd.visible,
    }));
    return axios
        .put(this._studioApiPath + paths.PRODUCTS_PATH + "/" + productId + paths.STYLEDDATASTUDIO_PATH, apiStyledData)
        .then(throwIfFalse("Could not update data and styles ordering."))
        .catch((error) => {
          throw new Error("Could not update data and styles ordering: " + error);
        }) as Promise<void>;
  }

  validateStyledData = (productId: string, styledData: StyledData[]) => {
    const apiStyledDatas = styledData.map((sd) => ({
      data: sd.data,
      style: sd.style,
    }));
    return axios
        .post(this._studioApiPath + paths.PRODUCTS_PATH + "/" + productId + paths.STYLEDDATA_PATH + "/validate",
            apiStyledDatas)
        .then(toData) as Promise<ValidationResult>;
  }

  login = ({username, password, redirect}: LoginOptions) => {
    return axios
        .post(this._getAbsoluteUrl(this._serverConfig.studio.login), toFormData({username, password, redirect}))
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<void>;
  }

  logout = () => {
    return axios
        .get(this._getAbsoluteUrl(this._serverConfig.studio.logout))
        .then(() => undefined) as Promise<void>;
  }

  getCurrentUser = () => {
    //to avoid chrome logging a 401 to the console as a network error, also mark 401 as ok
    return axios
        .get(this._studioServiceAPIPath + "/app-user/current")
        .then((r) => r.data) as Promise<User>;
  }

  setUserTourCompleted = (isUserTourCompleted: boolean) => {
    return axios
        .post(this._studioServiceAPIPath + "/app-user" +
              (isUserTourCompleted ? "/user-tour-completed" : "/user-tour-uncompleted"))
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<boolean>;
  }

  loadJobs = () => {
    return axios
        .get(this._studioApiPath + paths.CRAWLJOBS_PATH)
        .then((r) => r.data.crawlJobs)
        .catch(this.notifyErrorHandlers) as Promise<ImportJob[]>;
  }

  loadFileInfo = (filter: FileInfoSearchFilter) => {
    const apiFilter = formatApiFilter(filter, false);
    apiFilter.crawlJobPublicId = apiFilter.crawlJob;
    delete apiFilter.crawlJob;
    return axios
        .get(this._studioServiceAPIPath + paths.FILEINFO_PATH, {params: apiFilter})
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<FileInfo[]>;
  }

  getFileById = (id: number) => {
    return axios
        .get(this._studioServiceAPIPath + paths.FILEINFO_PATH + "/" + id)
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<FileInfo>;
  }

  getJobById = (jobId: string) => {
    return axios
        .get(this._studioApiPath + paths.CRAWLJOBS_PATH + "/" + jobId)
        .then((r) => r.data.crawlJob)
        .catch(this.notifyErrorHandlers) as Promise<ImportJob>;
  }

  stopJob = (jobId: string) => {
    return axios
        .put(this._studioApiPath + paths.CRAWLJOBS_PATH + "/" + jobId + "/stop")
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<boolean>;
  }

  queueJob = (jobId: string) => {
    return axios
        .put(this._studioApiPath + paths.CRAWLJOBS_PATH + "/" + jobId + "/enqueue")
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<boolean>;
  }

  validateJobSchedule = (jobId: string, schedule: string) => {
    return axios
        .put(this._studioApiPath + paths.CRAWLJOBS_PATH + "/" + jobId + "/validate-schedule", {schedule})
        .then(toData) as Promise<ValidationResult>;
  }

  updateJob = (job: ImportJob) => {
    return axios
        .patch(this._studioApiPath + paths.CRAWLJOBS_PATH + "/" + job.id, job)
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<ImportJob>;
  }

  loadPreprocessJobs = () => {
    const failedJobsPromise = axios
        .get(this._studioApiPath + paths.PREPROCESSJOBS_PATH, {params: {lastExecutionResult: "Failed"}});
    const stoppedJobsPromise = axios
        .get(this._studioApiPath + paths.PREPROCESSJOBS_PATH, {params: {lastExecutionResult: "Stopped"}});
    const didNotRunJobsPromise = axios
        .get(this._studioApiPath + paths.PREPROCESSJOBS_PATH, {params: {lastExecutionResult: "Did not run"}});

    return Promise.all([failedJobsPromise, stoppedJobsPromise, didNotRunJobsPromise])
        .then(([failedJosResponse, stoppedJobsResponse, didNotRunJobsResponse]) => {
          return failedJosResponse.data.preprocessJobs.concat(stoppedJobsResponse.data.preprocessJobs,
              didNotRunJobsResponse.data.preprocessJobs).sort((firstJob, secondJob) => {
            const firstDate = new Date(firstJob.creationTime);
            const secondDate = new Date(secondJob.creationTime);
            return firstDate.getTime() - secondDate.getTime();
          });
        }).catch(this.notifyErrorHandlers) as Promise<PreprocessJob[]>;
  }

  getPreprocessJobById = (jobId: string) => {
    return axios
        .get(this._studioApiPath + paths.PREPROCESSJOBS_PATH + "/" + jobId)
        .then((r) => r.data.preprocessJob)
        .catch(this.notifyErrorHandlers) as Promise<PreprocessJob>;
  }

  queuePreprocessJob = (jobId: string) => {
    return axios
        .put(this._studioApiPath + paths.PREPROCESSJOBS_PATH + "/" + jobId + "/enqueue")
        .then((r) => r.data.preprocessJob)
        .catch(this.notifyErrorHandlers) as Promise<boolean>;
  }

  stopPreprocessJob = (jobId: string) => {
    return axios
        .put(this._studioApiPath + paths.PREPROCESSJOBS_PATH + "/" + jobId + "/stop")
        .then((r) => r.data.preprocessJob)
        .catch(this.notifyErrorHandlers) as Promise<boolean>;
  }

  getPreprocessingPath = (serviceName: string) => {
    return axios
        .get(this._studioApiPath + paths.PREPROCESSJOBS_PATH + "/preprocessing-path", {params: {serviceName}})
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<string>;
  }

  getDataTypes = () => {
    return axios
        .get(this._studioServiceAPIPath + paths.IMPORTEDDATA_PATH + "/types")
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<string[]>;
  }

  getAllServiceTypes = () => {
    return axios
        .get(this._studioServiceAPIPath + paths.SERVICE_PATH + "/types/all")
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<ServiceTypeDetails[]>;
  }

  getEnabledServiceTypes = () => {
    return axios
        .get(this._studioServiceAPIPath + paths.SERVICE_PATH + "/types/enabled")
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<ServiceTypeDetails[]>;
  }

  getServiceUri = (serviceType: string, serviceName: string) => {
    return axios
        .get(this._studioServiceAPIPath + paths.SERVICE_PATH + "/endpoint-url/" + serviceType + "/" + serviceName)
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<string>;
  }

  getSuggestionText = (entityType: EntityType, text: string) => {
    return axios
        .get(this._studioServiceAPIPath + entityPath(entityType) + "/freeform", {params: {prefix: text}})
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<string[]>;
  }

  getImportedDataPreviewMetadata = (dataId: string) => {
    return axios
        .get(this._studioServiceAPIPath + paths.PREVIEW_PATH + "/preview-metadata/imported-data/" + dataId)
        .then(toData) as Promise<PreviewMetadata>;
  }

  getProductPreviewMetadata = (productId: string) => {
    return axios
        .get(this._studioServiceAPIPath + paths.PREVIEW_PATH + "/preview-metadata/product/" + productId)
        .then(toData) as Promise<PreviewMetadata>;
  }

  getBackgroundDataInfo = () => {
    return axios
        .get(this._studioServiceAPIPath + paths.PREVIEW_PATH + "/preview-background")
        .then(toData) as Promise<BackgroundDataInfo>;
  }

  setBackgroundDataFilePath = (filePath: string) => {
    return axios
        .put(this._studioServiceAPIPath + paths.PREVIEW_PATH + "/preview-background", {currentFilePath: filePath})
        .then(toData)
        .catch(this.notifyErrorHandlers) as Promise<void>;
  }

  addApiErrorHandler = (errorHandler: (error: Error) => void) => {
    this._errorHandlers.push(errorHandler);
    return this._errorHandlers.length - 1;
  }

  removeApiErrorHandler = (handle: ErrorHandlerHandle) => {
    this._errorHandlers.splice(handle, 1);
  }

  notifyErrorHandlers = (error) => {
    this._errorHandlers.forEach((handler) => handler(error));
    throw error;
  }

  getDefaultMetadata = () => {
    return axios
        .get(this._studioServiceAPIPath + "/default-metadata")
        .then(toData) as Promise<DefaultMetadata>;
  }

  getMappEnterpriseToken = (): Promise<string> => {
    if (!this.mappEntToken) {
      return Promise.resolve(null);
    } else {
      if (Date.now() >= this.mappEntToken.expiration_date) {
        const promise = this.mappEntRefreshPromise ||
                        this.refreshMappEnterpriseToken(this.mappEntToken.refresh_token);
        this.mappEntRefreshPromise = promise;
        return promise.then((token) => {
          delete this.mappEntRefreshPromise;
          return token.access_token;
        }).catch((e) => {
          delete this.mappEntRefreshPromise;
          throw e;
        });
      } else {
        return Promise.resolve(this.mappEntToken.access_token);
      }
    }
  }

  refreshMappEnterpriseToken = (refreshToken: string): Promise<METoken> => {
    return axios.post(this.mappEntUrl + paths.MAPPENT_TOKEN_PATH, stringify({
      grant_type: "refresh_token",
      refresh_token: refreshToken,
      client_id: "Studio",
    }), {
      headers: {
        Tenant: this.mappEntTenant,
      },
    }).then((response) => {
      this.setMappEntToken(response);
      return this.mappEntToken;
    });
  }

  setMappEntToken = (response: any) => {
    const token: METoken = response.data;
    token.expiration_date = Date.now() + (token.expires_in * 1000);
    this.mappEntToken = token;
  }

  applyTokenAuth = (config) => {
    config.headers.Authorization = "Bearer " + this.mappEntToken.access_token;
    return config;
  }

  performMappEnterpriseLogin = (refreshToken: string, tenant: string, url: string) => {
    this.mappEntUrl = url;
    this.mappEntTenant = tenant;
    return this.refreshMappEnterpriseToken(refreshToken).then(() => {
      // register a function to apply the token header on each request, unless it is to the MAE token URL
      axios.interceptors.request.use((c) => {
        if (!c.url.startsWith(this.mappEntUrl)) {
          return this.getMappEnterpriseToken().then(() => this.applyTokenAuth(c));
        } else {
          return c;
        }
      }, (e) => Promise.reject(e));
    }) as Promise<void>;
  }

  getVersion = () => {
    return axios
        .get(this._studioApiPath)
        .then((response) => response.data.productInfo)
        .catch(this.notifyErrorHandlers) as Promise<ProductInfo>;
  }

}

export const createStudioApi = (serverConfiguration: ServerConfiguration) => new ControlRoomApiClass(serverConfiguration);
