import {combineReducers} from "redux";
import {MappEntConfigurationInfo} from "../model";
import {actionTypes} from "./actions";
import {moduleName} from "./selectors";

export interface StartupState {
  mappEntConfigurationInfo: MappEntConfigurationInfo;
}

const mappEntConfigurationInfoReducer = (state: MappEntConfigurationInfo = null, action) => {
  switch (action.type) {
  case actionTypes.MAPP_ENT_CONFIGURATION_LOADED:
    return Object.assign({}, state, action.payload);
  case actionTypes.MISSING_ME_QUERY_PARAMS:
    return Object.assign({}, state, {...action.payload, missingMappEnterpriseInfo: true});
  case actionTypes.INVALID_ME_QUERY_PARAMS:
    return Object.assign({}, state, {...action.payload, invalidMappEnterpriseInfo: true});
  case "init":
    return {securityInfo: {isMappEnterprise: false}};
  }
  return state;
};

const reducer = combineReducers<StartupState>({
  mappEntConfigurationInfo: mappEntConfigurationInfoReducer,
});

export {
  moduleName,
  reducer,
};
