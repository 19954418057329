import * as React from "react";
import {FormattedMessage} from "react-intl";
import {EntityModel, SpatialModel} from "../../../model";
import {WithApiProperties} from "../../util/WithApi";
import {ErrorDisplay} from "../errordisplay/ErrorDisplay";
import {PreviewMetadata} from "../riamap/model";
import {Previewer} from "../riamap/Previewer";

interface DetailSectionState<T extends EntityModel> {
  data: T;
  isLoading: boolean;
  success: boolean;
  error: Error;
}

export interface DetailSectionProps<T extends EntityModel> {
  selection: T[];
}

export abstract class DetailSection<Props extends DetailSectionProps<DataToLoadType>, DataToLoadType extends EntityModel> extends React.Component<Props & WithApiProperties, DetailSectionState<DataToLoadType>> {

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      isLoading: false,
      success: false,
      error: null,
    };
  }

  componentDidMount() {
    this.fetchData(this.props);
  }

  // PMW remarks on upgrading to React 16.x as part of LF-1971:
  // componentWillReceiveProps has been deprecated and only the UNSAFE_* method will remain in react 17.x.
  // Deriving state from props is considered an anti-pattern, but I think the correct solution is to move this
  // functionality to the parent component, which I simply don't have time to do right now.
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selection && nextProps.selection.length <= 1) {
      this.fetchData(nextProps);
    } else {
      this.updateState({isLoading: false, success: false, error: null, data: null});
    }
  }

  abstract loadData(props: Props): Promise<DataToLoadType>;

  fetchData(props) {
    this.updateState({isLoading: true});
    this.loadData(props).then((data) => {
      this.updateState({data, isLoading: false, success: true, error: null});
    }).catch((error) => {
      this.updateState({data: null, isLoading: false, error});
    });
  }

  updateState = (updatedPart) => this.setState(Object.assign({}, this.state, updatedPart));

  renderContent(): React.ReactElement<any> {
    const {error, isLoading} = this.state;
    if (error) {
      return <ErrorDisplay error={error}/>;
    } else if (isLoading) {
      return <p><FormattedMessage id="studio.UI.detail-section.loading" defaultMessage="Loading..."/></p>;
    } else {
      return <p><FormattedMessage id="studio.UI.detail-section.no-data-selected" defaultMessage="No data selected"/>
      </p>;
    }
  }

  getDataToVisualize(): SpatialModel[] {
    return this.state.data ? [this.state.data] : [];
  }

  getPreviewBaseUrl(): string {
    return this.props.api.getDataPreviewBaseUrl();
  }

  getPreviewMetadataFunction(): (item: SpatialModel) => Promise<PreviewMetadata> {
    return (item) => this.props.api.getImportedDataPreviewMetadata(item.id);
  }

  render() {
    return (
        <div>
          <Previewer wmsBaseUrl={this.getPreviewBaseUrl()}
                     api={this.props.api}
                     dataToVisualize={this.getDataToVisualize()}
                     shouldFit={true}
                     shouldAnimateFit={true}
                     height="400px"
                     getPreviewMetadataFunction={this.getPreviewMetadataFunction()}
          />
          {this.renderContent()}
        </div>
    );
  }
}
