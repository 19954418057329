
export const actionTypes = {
  MAPP_ENT_CONFIGURATION_LOADED: "controlRoom/MAPP_ENT_CONFIGURATION_LOADED",
  MISSING_ME_QUERY_PARAMS: "controlRoom/MISSING_ME_QUERY_PARAMS",
  INVALID_ME_QUERY_PARAMS: "controlRoom/INVALID_ME_QUERY_PARAMS",
};

export const loadMappEntConfigurationInfo = (refreshToken: string, tenant: string) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    return api.getActuator("configurationInfo").then((configurationInfo) => {
      const securityInfo = configurationInfo.securityInfo;
      if (securityInfo.isMappEnterprise) {
        if (refreshToken && tenant) {
          return api.performMappEnterpriseLogin(refreshToken, tenant, securityInfo.mappEnterpriseSecurityInfo.authServiceUrl).then(() => {
            dispatch({
              type: actionTypes.MAPP_ENT_CONFIGURATION_LOADED,
              payload: configurationInfo,
            });
          }).catch((e) => {
            dispatch({
              type: actionTypes.INVALID_ME_QUERY_PARAMS,
              payload: configurationInfo,
            });
          });
        } else {
          dispatch({
            type: actionTypes.MISSING_ME_QUERY_PARAMS,
            payload: configurationInfo,
          });
        }
      } else {
        dispatch({
        type: actionTypes.MAPP_ENT_CONFIGURATION_LOADED,
          payload: configurationInfo,
        });
      }
      return configurationInfo;
    });
  };
};

export const actions = {
  loadMappEntConfigurationInfo,
};