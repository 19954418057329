import * as React from "react";
import {Alert, Button, Tab, Tabs} from "react-bootstrap";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {ActionBar} from "../common/ui/actionbar/ActionBar";
import {DetailHeader} from "../common/ui/detailsection/DetailHeader";
import {DetailMeta} from "../common/ui/detailsection/DetailMeta";
import {createCommonMetadataFields, DetailView, FieldInfo, Formats} from "../common/ui/detailsection/DetailView";
import {LcdIcon} from "../common/ui/icon/LcdIcon";
import {MasterDetailLayout} from "../common/ui/layouts/MasterDetailLayout";
import {Previewer} from "../common/ui/riamap/Previewer";
import {UploadOptions} from "../common/ui/upload/UploadOptions";
import {FileUploadAndInjectedProps, WithFileUpload} from "../common/ui/upload/WithFileUpload";
import {getParameterFromOwnProps} from "../common/util/Util";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {Product} from "../products/model";
import {actions} from "./actions";
import {
  getEndPointURL,
  Service,
  ServiceType,
  ServiceTypeDetails,
  supportsAccessConstraints,
  supportsContactInfo,
  supportsProducts
} from "./model";
import {selectors} from "./selectors";
import {ServiceDetailProducts} from "./ServiceDetailProducts";
import {ServiceGetCapabilitiesButton} from "./ServiceGetCapabilitiesButton";
import {ServiceStartStopButton} from "./ServiceStartStopButton";

interface ServiceProductsProps {
  serviceId: string;
  service: Service;
  products: Product[];
  loadService: () => void;
  loadServiceTypes: () => void;
  serviceTypeDetails: ServiceTypeDetails;
  serviceChanged: (newService: Service) => void;
}

const SERVICE_DETAIL_MESSAGES = defineMessages({
  id: {id: "studio.services.service-detail.id", defaultMessage: "ID"},
  name: {id: "studio.services.service-detail.name", defaultMessage: "Name"},
  type: {id: "studio.services.service-detail.type", defaultMessage: "Type"},
  accessConstraint: {id: "studio.services.service-detail.access-constraint", defaultMessage: "Access Constraint"},
  meshCompression: {
    id: "studio.services.service-detail.mesh-compression",
    defaultMessage: "Mesh Compression"
  },
  pointCloudCompression: {
    id: "studio.services.service-detail.point-cloud-compression",
    defaultMessage: "Point cloud Compression"
  },
  wfsTransactionsEnabled: {
    id: "studio.services.service-detail.wfs-transactions-enabled",
    defaultMessage: "Transactional"
  },
  wfsTransactionsHelp: {
    id: "studio.services.service-detail.wfs-transactions-help",
    defaultMessage: "Enables the Transaction, LockFeature and GetFeatureWithLock requests for this service. Transaction requests provide operations to insert, update and remove features in your data. The Transaction requests will only work for data that can be encoded by LuciadFusion."
  },
  sameAsSource: {id: "studio.services.service-detail.same-as-source", defaultMessage: "Same as source"},
  status: {id: "studio.services.service-detail.status", defaultMessage: "Status"},
  endpointURL: {id: "studio.services.service-detail.url", defaultMessage: "Endpoint URL"},
  preprocessingOutputPath: {
    id: "studio.services.service-detail.preprocessingOutputPath",
    defaultMessage: "Preprocessed Contents"
  },
  metadataLabel: {id: "studio.services.service-detail.metadata-header", defaultMessage: "Metadata"},
  contactIngoLabel: {
    id: "studio.services.service-detail.contact-information-header",
    defaultMessage: "Contact Information"
  },
  productsLabel: {id: "studio.services.service-detail.products-header", defaultMessage: "Products"},
  contactIndividualName: {id: "studio.services.service-detail.contact-individual-name", defaultMessage: "Person"},
  contactOrganizationName: {
    id: "studio.services.service-detail.contact-organization-name",
    defaultMessage: "Organization"
  },
  contactPositionName: {id: "studio.services.service-detail.contact-position", defaultMessage: "Position"},
  contactDeliveryPoint: {id: "studio.services.service-detail.contact-delivery-point", defaultMessage: "Address"},
  contactCity: {id: "studio.services.service-detail.contact-city", defaultMessage: "City"},
  contactAdministrativeArea: {
    id: "studio.services.service-detail.contact-administrative-area",
    defaultMessage: "Administrative Area"
  },
  contactPostCode: {id: "studio.services.service-detail.contact-post-code", defaultMessage: "Post Code"},
  contactCountry: {id: "studio.services.service-detail.contact-country", defaultMessage: "Country"},
  contactFax: {id: "studio.services.service-detail.contact-fax", defaultMessage: "Fax"},
  contactPhone: {id: "studio.services.service-detail.contact-phone", defaultMessage: "Phone"},
  contactEmail: {id: "studio.services.service-detail.contact-email", defaultMessage: "Email"},
});

type ServiceDetailProps = ServiceProductsProps & RouteComponentProps<{ id: string }>;

class ServiceDetailReactComponent extends React.Component<ServiceDetailProps & InjectedIntlProps & WithApiProperties & FileUploadAndInjectedProps, { key: string }> {

  constructor(props, context) {
    super(props, context);
    this.state = {
      key: "metadata",
    };
  }

  componentDidMount() {
    this.props.loadService();
    this.props.loadServiceTypes();
  }

  componentDidUpdate(prevProps: Readonly<ServiceDetailProps & ReactIntl.InjectedIntlProps & WithApiProperties & FileUploadAndInjectedProps>) {
    // Reload service when a new metadata file has been uploaded
    if (prevProps.isFileUploading && !this.props.isFileUploading) {
      this.props.loadService();
    }
  }

  renderContactInfoTab() {
    const {service, serviceChanged} = this.props;

    const fields: FieldInfo[] = [];

    fields.push({
      key: "ContactIndividualName",
      name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.contactIndividualName),
      value: service.contactInformation ? service.contactInformation.individualName : "",
      format: Formats.TEXT_EDIT,
      onChange: (newPerson) => {
        if (!this.props.service.contactInformation) {
          this.props.service.contactInformation = {};
        }
        this.props.service.contactInformation.individualName = newPerson;
        serviceChanged(this.props.service);
      },
    });
    fields.push({
      key: "ContactOrganizationName",
      name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.contactOrganizationName),
      value: service.contactInformation ? service.contactInformation.organizationName : "",
      format: Formats.TEXT_EDIT,
      onChange: (newOrganization) => {
        if (!this.props.service.contactInformation) {
          this.props.service.contactInformation = {};
        }
        this.props.service.contactInformation.organizationName = newOrganization;
        serviceChanged(this.props.service);
      },
    });
    fields.push({
      key: "ContactPositionName",
      name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.contactPositionName),
      value: service.contactInformation ? service.contactInformation.position : "",
      format: Formats.TEXT_EDIT,
      onChange: (newPosition) => {
        if (!this.props.service.contactInformation) {
          this.props.service.contactInformation = {};
        }
        this.props.service.contactInformation.position = newPosition;
        serviceChanged(this.props.service);
      },
    });
    fields.push({
      key: "ContactDeliveryPoint",
      name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.contactDeliveryPoint),
      value: service.contactInformation ? service.contactInformation.deliveryPoint : "",
      format: Formats.TEXT_EDIT,
      onChange: (newAddress) => {
        if (!this.props.service.contactInformation) {
          this.props.service.contactInformation = {};
        }
        this.props.service.contactInformation.deliveryPoint = newAddress;
        serviceChanged(this.props.service);
      },
    });
    fields.push({
      key: "ContactCity",
      name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.contactCity),
      value: service.contactInformation ? service.contactInformation.city : "",
      format: Formats.TEXT_EDIT,
      onChange: (newCity) => {
        if (!this.props.service.contactInformation) {
          this.props.service.contactInformation = {};
        }
        this.props.service.contactInformation.city = newCity;
        serviceChanged(this.props.service);
      },
    });
    fields.push({
      key: "ContactAdministrativeArea",
      name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.contactAdministrativeArea),
      value: service.contactInformation ? service.contactInformation.administrativeArea : "",
      format: Formats.TEXT_EDIT,
      onChange: (newStateOrProvince) => {
        if (!this.props.service.contactInformation) {
          this.props.service.contactInformation = {};
        }
        this.props.service.contactInformation.administrativeArea = newStateOrProvince;
        serviceChanged(this.props.service);
      },
    });
    fields.push({
      key: "ContactPostCode",
      name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.contactPostCode),
      value: service.contactInformation ? service.contactInformation.postCode : "",
      format: Formats.TEXT_EDIT,
      onChange: (newPostCode) => {
        if (!this.props.service.contactInformation) {
          this.props.service.contactInformation = {};
        }
        this.props.service.contactInformation.postCode = newPostCode;
        serviceChanged(this.props.service);
      },
    });
    fields.push({
      key: "ContactCountry",
      name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.contactCountry),
      value: service.contactInformation ? service.contactInformation.country : "",
      format: Formats.TEXT_EDIT,
      onChange: (newCountry) => {
        if (!this.props.service.contactInformation) {
          this.props.service.contactInformation = {};
        }
        this.props.service.contactInformation.country = newCountry;
        serviceChanged(this.props.service);
      },
    });
    fields.push({
      key: "ContactFax",
      name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.contactFax),
      value: service.contactInformation ? service.contactInformation.fax : "",
      format: Formats.TEXT_EDIT,
      onChange: (newFax) => {
        if (!this.props.service.contactInformation) {
          this.props.service.contactInformation = {};
        }
        this.props.service.contactInformation.fax = newFax;
        serviceChanged(this.props.service);
      },
    });
    fields.push({
      key: "ContactPhone",
      name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.contactPhone),
      value: service.contactInformation ? service.contactInformation.phone : "",
      format: Formats.TEXT_EDIT,
      onChange: (newPhone) => {
        if (!this.props.service.contactInformation) {
          this.props.service.contactInformation = {};
        }
        this.props.service.contactInformation.phone = newPhone;
        serviceChanged(this.props.service);
      },
    });
    fields.push({
      key: "ContactEmail",
      name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.contactEmail),
      value: service.contactInformation ? service.contactInformation.email : "",
      format: Formats.TEXT_EDIT,
      onChange: (newEmail) => {
        if (!this.props.service.contactInformation) {
          this.props.service.contactInformation = {};
        }
        this.props.service.contactInformation.email = newEmail;
        serviceChanged(this.props.service);
      },
    });
    return (
        <Tab eventKey="contactInformation"
             title={this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.contactIngoLabel)}>
          <div style={{marginTop: 20}}>
            <DetailView fields={fields}/>
          </div>
        </Tab>
    );
  }

  renderProductsSection() {
    const {service} = this.props;
    if (supportsProducts(service)) {
      return <ServiceDetailProducts service={service}/>;
    }
    return (
        <Alert bsStyle="info">
          <FormattedMessage id="studio.services.service-detail.support-error"
                            defaultMessage="This service does not support products."/>
        </Alert>
    );
  }

  getDetailPane = () => {
    const getPreviewMetadataFunction = (item) => this.props.api.getProductPreviewMetadata(item.id);
    return <Previewer wmsBaseUrl={this.props.api.getProductPreviewBaseUrl()}
                      api={this.props.api}
                      dataToVisualize={(this.props.products) || []}
                      shouldFit={true}
                      getPreviewMetadataFunction={getPreviewMetadataFunction}
    />;
  }

  getMasterPane = () => {
    const {service, products, serviceChanged, serviceTypeDetails} = this.props;
    const ID = this.props.match.params.id;
    if (!service) {
      return (
          <div>
            <h2><FormattedMessage id="studio.services.service-detail.ID-error"
                                  defaultMessage="Could not find service with id: {ID}" values={{ID}}/></h2>
            <p><Link to="/services"><FormattedMessage id="studio.services.service-detail.back-to-overview"
                                                      defaultMessage="Go back to services overview"/></Link></p>
          </div>
      );
    }

    const endPointUrl = getEndPointURL(service, products); //service.endpointUrl already includes /ogc
    const fields: FieldInfo[] = [];

    if (supportsAccessConstraints(service.type)) {
      fields.push({
        key: "accessConstraint",
        name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.accessConstraint),
        value: service.accessConstraint,
        format: Formats.TEXT_EDIT,
        onChange: (newAccessConstraint) => {
          this.props.service.accessConstraint = newAccessConstraint;
          serviceChanged(this.props.service);
        },
      });
    }
    fields.push({
      key: "Id",
      name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.id),
      value: service.id
    });
    fields.push({
      key: "Name",
      name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.name),
      value: service.name
    });
    fields.push({
      key: "Type",
      name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.type),
      value: service.type.toUpperCase()
    });
    if (service.type === ServiceType.WFS.toString()) {
      fields.push({
        key: "WFS Transactions Enabled",
        name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.wfsTransactionsEnabled),
        help: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.wfsTransactionsHelp),
        value: service.wfsTransactionsEnabled === true,
        format: Formats.CHECKBOX,
        onChange: (checked) => {
          this.props.service.wfsTransactionsEnabled = checked;
          serviceChanged(this.props.service);
        },
      });
    }
    fields.push({
      key: "Status",
      name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.status),
      value: service.status
    });
    fields.push({
      key: "Endpoint URL",
      name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.endpointURL),
      value: endPointUrl,
    });

    if (service.type === ServiceType.OGC3DTiles) {
      fields.push({
        key: "Mesh Compression",
        name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.meshCompression),
        value: service.meshCompression ? service.meshCompression
                                       : this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.sameAsSource),
      });
      fields.push({
        key: "Point cloud Compression",
        name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.pointCloudCompression),
        value: service.pointCloudCompression ? service.pointCloudCompression
                                             : this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.sameAsSource),
      });
    }

    if (service.preprocessingOutputPath) {
      fields.push({
        key: "Preprocessed Contents",
        name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.preprocessingOutputPath),
        value: service.preprocessingOutputPath,
      });
    }

    return (
        <div>
          <DetailHeader title={service.name}/>

          <ActionBar>
            <ServiceStartStopButton service={service}/>
            <ServiceGetCapabilitiesButton service={service} serviceTypeDetails={serviceTypeDetails}/>
            <Button onClick={this.props.openUpload}><LcdIcon icon="import"/>
              <FormattedMessage id="studio.services.service-detail.import-metadata" defaultMessage="Import metadata"/>
            </Button>
            <Button onClick={() => {
              this.props.api.downloadServiceMetadata(service);
            }}>
              <LcdIcon icon="download-alt"/><FormattedMessage
                id="studio.services.service-detail.download-metadata"
                defaultMessage="Download metadata"/>
            </Button>
          </ActionBar>
          <Tabs id="ServiceDetailTabs" bsStyle="pills" justified={false} animation={true} className={"tabs"}
                activeKey={this.state.key} onSelect={(key) => this.setState({key})}>
            <Tab eventKey="metadata" title={this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.metadataLabel)}>
              <DetailMeta metadata={service}
                          updateEntity={(newMetadata) => serviceChanged(
                              Object.assign({}, service, {
                                title: newMetadata.title,
                                keywords: newMetadata.keywords,
                                abstractText: newMetadata.abstractText,
                              }))}
              />
              <DetailView fields={fields.concat(createCommonMetadataFields(service, this.props.intl))}/>
            </Tab>
            {supportsContactInfo(service.type) ? this.renderContactInfoTab() : null}
            <Tab eventKey="products" title={this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.productsLabel)}>
              <div style={{marginTop: 20}}>
                {this.renderProductsSection()}
              </div>
            </Tab>
          </Tabs>
        </div>
    );
  }

  render() {
    return (
        <div>
          <MasterDetailLayout masterPane={this.getMasterPane()}
                              detailPane={this.getDetailPane()}
                              evenSplit={true}/>
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = getParameterFromOwnProps(ownProps, "id"); //maybe injected by withRouter
  const service = selectors.getServiceById(state, id);
  const serviceTypeDetails = getServiceTypeDetails(service, selectors.getAllServiceTypes(state));
  return {
    serviceId: id,
    service,
    serviceTypeDetails,
    products: selectors.getServiceProducts(state, id),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = getParameterFromOwnProps(ownProps, "id"); //maybe injected by withRouter
  return {
    serviceId: id,
    loadService: () => dispatch(actions.requestItemById(id)),
    loadServiceTypes: () => dispatch(actions.loadAllServiceTypes()),
    serviceChanged: (newService: Service) => dispatch(actions.updateService(newService)),
    createFormData: (uploadFiles: File[]) => {
      const data = new FormData();
      data.append("serviceId", id);
      data.append("metadataFile", uploadFiles[0]);
      return data;
    },
  };
};

const getServiceTypeDetails = (service: Service, serviceTypeDetails: ServiceTypeDetails[]) => {
  if (service && serviceTypeDetails) {
    return serviceTypeDetails.find((serviceConf) => serviceConf.serviceType === service.type);
  }

  return undefined;
};

const serviceMetadataUploadOptions: UploadOptions = {
  allowedFileExtension: ".xml",
  allowMultipleFiles: false,
  uploadUri: "/upload/service-metadata",
};

export const ServiceDetailComponent = injectIntl(ServiceDetailReactComponent);
const ServiceDetailPageComponentWithFileUpload = WithFileUpload(serviceMetadataUploadOptions)(ServiceDetailComponent);

export const ServiceDetail = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(WithApi(ServiceDetailPageComponentWithFileUpload)));
