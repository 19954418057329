import {CancelToken} from "axios";
import {BackgroundDataInfo, PreviewMetadata} from "../common/ui/riamap/model";
import {TOUR_DATA} from "../common/ui/tour/UserTourData";
import {ValidationResult} from "../common/ui/validation/ProductValidation";
import {ServiceTypeValidation} from "../common/ui/validation/ServiceTypeValidation";
import {FileInfo, ImportedData, ImportedDataSearchFilter} from "../data/model";
import {FileInfoSearchFilter, ImportJob, PreprocessJob} from "../jobs/model";
import {Bounds, DirectoryItem, EntityType, ServerConfiguration} from "../model";
import {DefaultMetadata} from "../common/defaultMetadata/model";
import {Product, ProductFilter, StyledData} from "../products/model";
import {Service, ServiceTypeDetails} from "../services/model";
import {DataRoot} from "../settings/dataroots/model";
import {StyleSearchFilter} from "../styles/filter/model";
import {Style} from "../styles/model";
import {User} from "../user/model";
import {Api, LoginOptions} from "./InternalApi";
import {ControlRoomApiClass, createStudioApi} from "./StudioInternalApi";
import {DeleteResponse, ProductInfo} from "../common/model";

export class UserTourApiClass implements Api {

  private _studioApi: ControlRoomApiClass;

  constructor(serverConfiguration: ServerConfiguration) {
    this._studioApi = createStudioApi(serverConfiguration);
  }

  // Enabled
  getAbsoluteUrl = (basePath: string) => this._studioApi.getAbsoluteUrl(basePath);
  getStudioInternalApiUrl = () => this._studioApi.getStudioInternalApiUrl();
  getStudioNotificationsUrl = () => this._studioApi.getStudioNotificationsUrl();
  getDataPreviewBaseUrl = () => this._studioApi.getDataPreviewBaseUrl();
  getStylePreviewBaseUrl = () => this._studioApi.getStylePreviewBaseUrl();
  getProductPreviewBaseUrl = () => this._studioApi.getProductPreviewBaseUrl();
  getPreviewBackgroundUrl = () => this._studioApi.getPreviewBackgroundUrl();
  getBackgroundDataName = () => this._studioApi.getBackgroundDataName();
  getDataRoots = () => Promise.resolve(TOUR_DATA.contentRoots) as Promise<DataRoot[]>;
  getImportedData = (filter: ImportedDataSearchFilter) => Promise.resolve(
      TOUR_DATA.importedData) as Promise<ImportedData[]>;
  getStyles = (filter: StyleSearchFilter) => Promise.resolve(TOUR_DATA.styles) as Promise<Style[]>;
  setUserTourCompleted= (isUserTourCompleted: boolean) => this._studioApi.setUserTourCompleted(isUserTourCompleted);

  // Not used
  getFileById = (id: number) => Promise.resolve(null) as Promise<FileInfo>;
  registerContentRoot = (contentRoot: DataRoot) => Promise.resolve(null) as Promise<DataRoot>;
  getMaxUploadSize= () => Promise.resolve(0) as Promise<number>;
  uploadFile = (data: FormData, fileUploadProgress, cancelToken: CancelToken, uploadUri: string) => Promise.resolve([]);
  downloadMetadata = (data: ImportedData) => null;
  downloadServiceMetadata = (service: Service) => null;
  browse = (rootPath: string) => Promise.resolve([]) as Promise<DirectoryItem[]>;
  getImportedDataById = (id: string) => Promise.resolve(null) as Promise<ImportedData>;
  getAllImportedDataFilesByImportedDataId = (id: string) => Promise.resolve(null) as Promise<FileInfo[]>;
  deleteImportedData = (ids: string[]) => Promise.resolve(null) as Promise<DeleteResponse>;
  addImportedData = (filePath: string) => Promise.resolve(null) as Promise<ImportedData[]>;
  refreshImportedData = (id: string) => Promise.resolve(null) as Promise<ImportedData>;
  updateImportedData = (data: ImportedData) => Promise.resolve(null) as Promise<ImportedData>;
  listServices = () => Promise.resolve([]) as Promise<Service[]>;
  serviceById = (id: string) => Promise.resolve(null) as Promise<Service>;
  serviceByName = (name: string) => Promise.resolve(null) as Promise<Service>;
  isServiceStarted = (id: string) => Promise.resolve(false) as Promise<boolean>;
  createService = (service: Service) => Promise.resolve(null) as Promise<Service>;
  updateService = (service: Service) => Promise.resolve(null) as Promise<Service>;
  deleteService = (id: string) => Promise.resolve() as Promise<void>;
  startService = (id: string) => Promise.resolve(null) as Promise<Service>;
  stopService = (id: string) => Promise.resolve(null) as Promise<Service>;
  getFullServiceById = (id: string) => Promise.resolve(null) as Promise<Service>;
  getProductsForService = (serviceId: string) => Promise.resolve([]) as Promise<Product[]>;
  addProductToService = (serviceId: string, productId: string) => Promise.resolve();
  addProductsToService = (serviceId: string, productIds: string[]) => Promise.resolve();
  removeProductFromService = (serviceId: string, productId: string) => Promise.resolve();
  validateProduct = (serviceId: string, productId: string) => Promise.resolve(
      {severity: "OK", errorMessages: [], warningMessages: []}) as Promise<ValidationResult>;
  validateProducts = (serviceId: string, productIds: string[]) => Promise.resolve([]) as Promise<ValidationResult[]>;
  serviceTypeValidation = (p1: Product[]) => Promise.resolve(null) as Promise<ServiceTypeValidation>;
  setServiceProducts = (serviceId: string, products: Product[]) => Promise.resolve();
  createProduct = (product: Product) => Promise.resolve(null) as Promise<Product>;
  loadProducts = (filter: ProductFilter = null) => Promise.resolve([]) as Promise<Product[]>;
  updateProduct = (product: Product) => Promise.resolve(null) as Promise<Product>;
  deleteProduct = (id: string) => Promise.resolve() as Promise<void>;
  getStyleById = (id: string) => Promise.resolve(null) as Promise<Style>;
  deleteStyles = (ids: string[]) => Promise.resolve();
  addStyle = (filePath: string) => Promise.resolve(null) as Promise<Style>;
  refreshStyle = (id: string) => Promise.resolve(null) as Promise<Style>;
  getActuator = (type: string, headers?: any) => Promise.resolve(
      TOUR_DATA.actuators[type] ? TOUR_DATA.actuators[type] : null) as Promise<any>;
  getStyleBoundsByType = (type: string) => Promise.resolve(null) as Promise<Bounds>;
  updateStyle = (style: Style) => Promise.resolve(null) as Promise<Style>;
  loadProductContents = (id: string) => Promise.resolve([]) as Promise<StyledData[]>;
  getProductServices = (id: string) => Promise.resolve([]) as Promise<Service[]>;
  getProductById = (id: string) => Promise.resolve(null) as Promise<Product>;
  createOrUpdateStyledData = (productId: string, styledData: StyledData[]) => Promise.resolve(null) as Promise<StyledData[]>;
  setStyledData = (productId: string, styledData: StyledData[]) => Promise.resolve();
  validateStyledData = (productId: string, styledData: StyledData[]) => Promise.resolve(
      {severity: "OK", errorMessages: [], warningMessages: []}) as Promise<ValidationResult>;
  login = ({username, password}: LoginOptions) => Promise.resolve() as Promise<void>;
  logout = () => Promise.resolve() as Promise<void>;
  getCurrentUser = () => Promise.resolve(null) as Promise<User>;
  loadJobs = () => Promise.resolve([]) as Promise<ImportJob[]>;
  loadFileInfo = (filter: FileInfoSearchFilter) => Promise.resolve([]) as Promise<FileInfo[]>;
  getJobById = () => Promise.resolve(null) as Promise<ImportJob>;
  stopJob = (jobId: string) => Promise.resolve(true) as Promise<boolean>;
  queueJob = (jobId: string) => Promise.resolve(true) as Promise<boolean>;
  validateJobSchedule = (jobId: string, schedule: string) => Promise.resolve(
      {severity: "OK", errorMessages: [], warningMessages: []}) as Promise<ValidationResult>;
  updateJob = (job: ImportJob) => Promise.resolve(job) as Promise<ImportJob>;
  loadPreprocessJobs = () => Promise.resolve([]) as Promise<PreprocessJob[]>;
  getPreprocessJobById = () => Promise.resolve(null) as Promise<PreprocessJob>;
  queuePreprocessJob = (jobId: string) => Promise.resolve(true) as Promise<boolean>;
  stopPreprocessJob = (jobId: string) => Promise.resolve(true) as Promise<boolean>;
  getPreprocessingPath = (serviceName: string) => Promise.resolve(`/test/}`) as Promise<string>;
  getDataTypes = () => Promise.resolve([]) as Promise<string[]>;
  getAllServiceTypes = () => Promise.resolve([]) as Promise<ServiceTypeDetails[]>;
  getEnabledServiceTypes = () => Promise.resolve([]) as Promise<ServiceTypeDetails[]>;
  getServiceUri = (serviceType: string, serviceName: string) => Promise.resolve(`${serviceType}/test-${serviceName}`);
  deleteContentRoot = (dataRootId: string) => Promise.resolve(true);
  getSuggestionText = (entityType: EntityType, text: string) => Promise.resolve([]);
  getImportedDataPreviewMetadata = (dataId: string) => Promise.resolve(null) as Promise<PreviewMetadata>;
  getProductPreviewMetadata = (dataId: string) => Promise.resolve(null) as Promise<PreviewMetadata>;
  canPreviewStyleType = (styleType: string) => Promise.resolve(true) as Promise<boolean>;
  addApiErrorHandler = (errorHandler) => -1 /* unused */;
  removeApiErrorHandler = (handle) => {/*unused*/
  };
  getBackgroundDataInfo = () => Promise.resolve(null) as Promise<BackgroundDataInfo>;
  setBackgroundDataFilePath = (filePath: string) => Promise.resolve();
  performMappEnterpriseLogin = (refreshToken: string, tenant: string, url: string) => Promise.resolve();
  getMappEnterpriseToken = () => Promise.resolve("");
  getDefaultMetadata = () => Promise.resolve({} as DefaultMetadata);
  getVersion = () => Promise.resolve(null) as Promise<ProductInfo>;
}

export const createUserTourApi = (serverConfig: ServerConfiguration) => new UserTourApiClass(serverConfig);
