import {AxiosError} from "axios";
import * as React from "react";
import {Button, Panel} from "react-bootstrap";
import {defineMessages, FormattedMessage, injectIntl} from "react-intl";
import {WithApi, WithApiProperties} from "../../util/WithApi";
import InjectedIntlProps = ReactIntl.InjectedIntlProps;

export interface ErrorDisplayState {
  additionalInfoOpen: boolean;
}

export interface ErrorDisplayProps {
  error: Error;
}

const ERROR_MESSAGES = defineMessages({
  unknownNotPresent: {
    id: "studio.error-display.unknown-not-present",
    defaultMessage: "Unknown/not present",
  },
  anErrorOccured: {
    id: "studio.error-display.an-error-occured",
    defaultMessage: "An error occurred: {message}",
  },
});

class ErrorDisplayComponent extends React.Component<ErrorDisplayProps & InjectedIntlProps & WithApiProperties, ErrorDisplayState> {

  constructor(props) {
    super(props);
    this.state = {
      additionalInfoOpen: false,
    };
  }

  render() {
    const {error, intl} = this.props;
    let userFriendlyMessage = error.message;
    let additionalInfo = null;
    if (error.message.indexOf("Network") >= 0) {
      const expectedBackendLocationLink = this.props.api.getStudioInternalApiUrl();
      additionalInfo = (
          <div>
            <p><u><b><FormattedMessage id="studio.error-display.information" defaultMessage="Information:"/></b></u></p>
            <p>
              <FormattedMessage id="studio.error-display.network-error-description"
                                defaultMessage="This error usually occurs when LuciadFusion Studio can not connect to the LuciadFusion Platform. Check if the LuciadFusion Studio Service is running at {backendLocationLink}."
                                values={{
                                  backendLocationLink: (
                                      <a href={expectedBackendLocationLink}>{expectedBackendLocationLink}</a>
                                  ),
                                }}
              />
            </p>
          </div>
      );
    }
    //network error, potentially with axios response attached
    if ((error as any).config) {
      const axiosError = error as AxiosError;
      const response = axiosError.response;
      additionalInfo = (
          <div>
            <h3><FormattedMessage id="studio.error-display.request-information"
                                  defaultMessage="Request information:"
            /></h3>
            <p>
              <strong>
                <FormattedMessage id="studio.error-display.url" defaultMessage="URL:" />
              </strong>
              {axiosError.config.url || <FormattedMessage id="studio.error-display.unknown" defaultMessage="Unknown" />}
            </p>
            <p>
              <strong><FormattedMessage id="studio.error-display.request-data" defaultMessage="Request data:" /></strong>
              {axiosError.config.data || intl.formatMessage(ERROR_MESSAGES.unknownNotPresent)}
            </p>
            {response ? (
                <div>
                  <h3><FormattedMessage id="studio.error-display.response-information" defaultMessage="Response Information:" /></h3>
                  <p>
                    <strong><FormattedMessage id="studio.error-display.response-status" defaultMessage="Response status:" /> </strong>
                    {response.status + " " + response.statusText}
                  </p>
                  {response.data.message ? <p><strong><FormattedMessage id="studio.error-display.response-message" defaultMessage="Response message:" /> </strong>{response.data.message}</p> : null}
                  <p>
                    <strong><FormattedMessage id="studio.error-display.response-data" defaultMessage="Response data:" /></strong>
                    {response.data ? JSON.stringify(response.data) : intl.formatMessage(ERROR_MESSAGES.unknownNotPresent)}
                  </p>
                </div>
            ) : null}
          </div>
      );

      if (response && response.data && response.data.message) {
        userFriendlyMessage = response.data.message;
      }
    }

    const message = intl.formatMessage(ERROR_MESSAGES.anErrorOccured, {
      message: userFriendlyMessage,
    });

    return (
        <div>
          <div>
            <h3>{message}</h3>
          </div>

          <div>
            <Button onClick={() => this.setState({ additionalInfoOpen: !this.state.additionalInfoOpen })}>
              <FormattedMessage id="studio.error-display.more-info" defaultMessage="More info" />
            </Button>
            <Panel collapsible expanded={this.state.additionalInfoOpen}>
              {additionalInfo}
              <p><u><b><FormattedMessage id="studio.error-display.details" defaultMessage="Details:" /></b></u></p>
              <p><b><FormattedMessage id="studio.error-display.error-message" defaultMessage="Error message:" /> </b></p>
              <pre><code>
                {error.message}
            </code></pre>
              <p><b><FormattedMessage id="studio.error-display.stack" defaultMessage="Stack:" /></b></p>
              <pre><code>
                {error.stack}
            </code></pre>
            </Panel>
          </div>
        </div>
    );
  }
}

export const ErrorDisplay = WithApi(injectIntl(ErrorDisplayComponent));
