// only works when called from /index.html, make sure these resources remain siblings of index.html in WebApp resource configuration
export const SERVER_CONFIG_PATH = "./server-config.json";
export const TRANSLATIONS_PATH = "./translations";
export const TRANSLATIONS_LOCALE_DATA_PATH = "./intl/locale-data";

// These represent the Controller mapping of Studio Service
// These are relative to the Studio Service Rest API and the Studio Service Internal Rest API
// !!! These needs to be kept un sync with the Studio Service Controllers manually !!!

export const IMPORTEDDATA_PATH = "/imported-data";
export const STYLE_PATH = "/style";
export const STYLES_PATH = "/styles";
export const PRODUCT_PATH = "/product";
export const PRODUCTS_PATH = "/products";
export const SERVICE_PATH = "/services";
export const STYLEDDATA_PATH = "/styled-data";

export const PRODUCTDATA_PATH = "/product-data";
export const STYLEDDATASTUDIO_PATH = "/styled-data-studio";
export const CRAWLJOBS_PATH = "/crawl-jobs";
export const PREPROCESSJOBS_PATH = "/preprocess-jobs";
export const PREVIEW_PATH = "/preview";
export const FILEINFO_PATH = "/file-info";

export const MAPPENT_TOKEN_PATH = "/token";

/**
 *  returns the origin of the current page. That is, the part of this page's URL before the path
 *  e.g. if the page is https://myhost.mydomain.com/context/studio/index.html
 *  this would return https://myhost.mydomain.com/
 */
export const getOrigin = (): string => {
  return `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
}

/**
 * Returns the page url without the querystring and fragment (everything before ?/#).
 */
export const getPageURL = (): string => {
  return getOrigin() + `${window.location.pathname}`;
};
