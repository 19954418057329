import * as React from "react";
import {Button} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {LcdIcon} from "../common/ui/icon/LcdIcon";
import {Service, ServiceTypeDetails} from "./model";
import {WithApi, WithApiProperties} from "../common/util/WithApi";

interface ServiceGetCapabilitiesButtonStateProps {
  service: Service;
  serviceTypeDetails: ServiceTypeDetails;
}

interface ServiceGetCapabilitiesButtonOtherProps {
  bsSize?: string;
  disabled?: boolean;
}

type ServiceGetCapabilitiesButtonProps =
    ServiceGetCapabilitiesButtonStateProps
    & ServiceGetCapabilitiesButtonOtherProps
    & WithApiProperties;

const shouldShowCapabilities = (serviceTypeDetails: ServiceTypeDetails) => {
  return serviceTypeDetails && serviceTypeDetails.hasCapabilities;
};

export class ServiceGetCapabilitiesButtonComponent extends React.Component<ServiceGetCapabilitiesButtonProps, {}> {

  render() {
    const endPointUrl = this.props.api.getAbsoluteUrl(this.props.service.endpointPath);
    const getCapabilitiesUrl = `${endPointUrl}?service=${this.props.service.type.toUpperCase()}&request=GetCapabilities`;

    if (shouldShowCapabilities(this.props.serviceTypeDetails)) {
      return <Button onClick={(event) => window.open(getCapabilitiesUrl, "_blank")}>
        <LcdIcon icon="search"/>
        <FormattedMessage id="studio.services.service-get-capabilities" defaultMessage="Get Capabilities"/>
      </Button>;
    }

    return null;
  }

}

export const ServiceGetCapabilitiesButton = WithApi(ServiceGetCapabilitiesButtonComponent);
